<template>
  <div class="container">
    <div class="row page-content">
      <section class="col-4">
        <div class="box-container">
          <h3>Projektek</h3>
          <div class="white-box">
            <div class="listitem row">
              <div class="col-auto">
                <button class="img">
                  <BaseIcon  name="office" />
                </button>
              </div>
              <div class="col-7">
                <button class="title">Projekt név</button>
                <div class="date">lorem ispum</div>
              </div>
              <div class="col">
               <div class="status"><div class="status-bar" style="width: 40%;"></div></div>
              </div>
            </div>
            <div class="listitem row">
            </div>
            <div class="listitem row">
            </div>
          </div>
        </div>
      </section>
      <section class="col-8">
        <div class="box-container">
          <h2>Projekt információk</h2>
          <div class="white-box p-3">
            <h3>Alapok</h3>
            <div class="box-row">
              <div class="d-flex px-3 py-2">
                <div class="flex-1-1-0">Állapot</div>
                <div>Folyamatban</div>
              </div>
              <div class="d-flex px-3 py-2">
                <div class="flex-1-1-0">Projekt kezdete</div>
                <div>2020.12.24</div>
              </div>
            </div>
          </div>
        </div>
        <div class="box-container">
          <h2>Feladatok</h2>
        </div>
      </section>
    </div>
    <div class="col-12">
      <div class="box-container">
        <h2>Ütemezés</h2>
        <Services />
      </div>
    </div>
  </div>
  <!--
  <div class="container">
    <div class="row">
      <div class="col-4">
        <h2>Backlog</h2>
        <div class="white-box task">
          <div class="project-name badge">IntoMedia</div>
          <h3>Feladat neve</h3>
          <p>Lorem adask fre f ew fé flweél sldl d áésf séald léew áéadsáés fáw efcáésd dasdwq www</p>
          <div class="white-box-bottom d-flex">
            <div>34 nap</div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <h2>Folyamatban</h2>
      </div>
      <div class="col-4">
        <h2>Kész</h2>
      </div>
    </div>
  </div>-->
</template>

<script>
import BaseIcon from "../../components/BaseIcon";
import Services from "./Services";
export default {
  name: "Projects",
  components: { Services, BaseIcon },
};
</script>